<script>
  import { get, call, sync } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import AddressForm from '@/components/account/AddressForm'
  export default {
    components: {
      AddressForm,
    },
    data() {
      return {
        formRef: null,
        address: null,
        addressId: null,
      }
    },
    computed: {
      ...get(['route']),
      ...get('account', ['findAddressIdBySubscriptionId']),
      ...sync('subscribe', ['selectedShippingAddressId']),
    },
    mounted() {
      const subscriptionId = this.$route.params.subscriptionId
      this.selectedShippingAddressId = this.findAddressIdBySubscriptionId(subscriptionId)
    },
    methods: {
      ...call('account', ['updateSubscriptionAddress']),
      async submit(address) {
        if (this.selectedShippingAddressId === 'new') {
          this.address = address
        } else {
          this.addressId = this.selectedShippingAddressId
        }
        try {
          const addressData = {
            address: this.address,
            addressId: this.addressId,
            subscriptionId: this.route.params.subscriptionId,
          }
          await this.formRef.process(this.updateSubscriptionAddress(addressData))
          await this.$store.dispatch('account/load')
          this.$navigate({ name: 'AccountProfileSubscriptions' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <AddressForm v-ref="formRef" select-option @submit="submit($event)">
      Change Shipping Address
      <p class="text-sm font-normal leading-snug-tight mt-2">
        *If you are changing your shipping address midseason, please email customer support to
        confirm your box and/or order will be shipped to the correct address,
        <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>.
      </p>
      <template v-slot:buttonLabel>Save Address</template>
    </AddressForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSubscriptions' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
